@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";
@import "tailwindcss/components.css";
@import "tailwindcss/utilities.css";
@import "tailwindcss/base.css";
@import "./fonts.scss";
@import "./theme.css";

// .colors { color: #0f2d40; color: #cb583d; }

// * {
//   font-family: "Be Vietnam Pro", sans-serif !important;
// }

* {
  --dea-transition-time: 0.35s;
  --dea-transform-scale: 1.05;
}

.fs075rem {
  font-size: 0.75rem;
}

.dea-scale-animation {
  transition: var(--dea-transition-time);
  transform: scale(1);
}

.dea-scale-animation:hover {
  transform: scale(var(--dea-transform-scale));
  transition: var(--dea-transition-time);
}
