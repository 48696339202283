/* be-vietnam-pro-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 100;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-100.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-100italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 100;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-100italic.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 200;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-200.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 200;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-200italic.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 300;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-300.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 300;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-300italic.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-regular.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 400;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-italic.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 500;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-500.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 500;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-500italic.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 600;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-600.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 600;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-600italic.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 700;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-700.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 700;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-700italic.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 800;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-800.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 800;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-800italic.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 900;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-900.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* be-vietnam-pro-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 900;
  src: url("https://static.deamicis.cloud/fonts/be-vietnam-pro/be-vietnam-pro-v10-latin-900italic.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
